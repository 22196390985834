type HexColor = { r: number; g: number; b: number }

/**
 * Given a hex-based color, like '#abcdef', it breaks them into their respective red,
 * green and blue sections in base 10.
 *
 * @param {string} hex a color expressed in hexadecimal format, like '#c0ffee'.
 * @returns {HexColor} an object with numbers for each of the red, green and blue channels.
 */
const hexToRgb = (hex: string): HexColor | null => {
  const validHexColorChannels =
    /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex)
  if (!validHexColorChannels) return null
  return {
    r: Number.parseInt(validHexColorChannels[1], 16),
    g: Number.parseInt(validHexColorChannels[2], 16),
    b: Number.parseInt(validHexColorChannels[3], 16),
  }
}

/**
 * Given a hex-based color (e.g. '#123456') and an alpha channel, applies transparency
 * to the original color and makes it rgba.
 *
 * @param {string} hex a color expressed in hexadecimal, like '#c0ffee'.
 * @param {number} alpha the alpha channel (transparency) from 0 to 1. Defaults to 1.
 * @returns {string} a color in rgba format.
 */
const hexToRgba = (hex: string, alpha = 1): string => {
  const rgbDissected = hexToRgb(hex)
  if (!rgbDissected) return hex
  const { r, g, b } = rgbDissected
  return `rgba(${r},${g},${b},${alpha})`
}

export default hexToRgba
