import hexToRgba from '../utils/color'

export const measure = {
  0: `0rem`,
  1: `0.06rem`,
  2: `0.125rem`,
  4: `0.25rem`,
  6: `0.375rem`,
  8: `0.5rem`,
  12: `0.75rem`,
  14: `0.875rem`,
  16: `1rem`,
  18: `1.125rem`,
  20: `1.25rem`,
  22: `1.375rem`,
  24: `1.5rem`,
  26: `1.625rem`,
  28: `1.75rem`,
  30: `1.875rem`,
  32: `2rem`,
  34: `2.125rem`,
  36: `2.25rem`,
  40: `2.5rem`,
  42: `2.625rem`,
  48: `3rem`,
  52: `3.25rem`,
  56: `3.5rem`,
  60: `3.75rem`,
  64: `4rem`,
  72: `4.5rem`,
  76: `4.75rem`,
  80: `5rem`,
  96: `6rem`,
} as const

// FONTS

export const size = {
  4: measure[4],
  6: measure[6],
  8: measure[8],
  12: measure[12],
  14: measure[14],
  16: measure[16],
  18: measure[18],
  20: measure[20],
  24: measure[24],
  26: measure[26],
  28: measure[28],
  30: measure[30],
  32: measure[32],
  36: measure[36],
  40: measure[40],
  42: measure[42],
  48: measure[48],
  52: measure[52],
  56: measure[56],
  60: measure[60],
  72: measure[72],
} as const

export const weight = {
  regular: '400',
  medium: '500',
  semibold: '600',
} as const

export const lineHeight = {
  14: measure[14],
  16: measure[16],
  18: measure[18],
  20: measure[20],
  22: measure[22],
  24: measure[24],
  26: measure[26],
  34: measure[34],
  40: measure[40],
  48: measure[48],
} as const

// COLORS

export const colors = {
  background: {
    overlay: {
      primary: hexToRgba('#000000', 0.5),
    },
    info: {
      primary: `#E3F7FA`,
    },
    critical: {
      primary: '#FCEDED',
      secondary: '#E03F3F',
    },
    positive: {
      primary: '#EDFCF2',
    },
    warning: {
      primary: '#FCF4ED',
      inverted: '#ED9339',
    },
    neutral: {
      primary: '#FFFFFF',
      secondary: '#F2F5F4',
      tertiary: '#DCE0DE',
    },
    action: {
      inverted: {
        primary: {
          default: '#FFFFFF',
          hover: hexToRgba('#FFFFFF', 0.88),
          pressed: hexToRgba('#FFFFFF', 0.72),
          disabled: hexToRgba('#FFFFFF', 0.88),
        },
        secondary: {
          hover: hexToRgba('#000000', 0.08),
          pressed: hexToRgba('#000000', 0.16),
          disabled: hexToRgba('#000000', 0.56),
        },
      },
      primary: {
        default: '#008059',
        hover: '#005C40',
        pressed: '#003D2B',
        disabled: '#CACCCB',
      },
      secondary: {
        default: '#FFFFFF',
        hover: '#E6FAF3',
        pressed: '#B2EBD0',
        disabled: '#F2F5F4',
      },
      tertiary: {
        default: '#FFFFFF',
        hover: '#F2F5F4',
        pressed: '#DCE0DE',
      },
      alternate1: '#0D0F0F',
      alternate2: {
        default: '#F2F5F4',
        hover: '#DCE0DE',
        pressed: '#CACCCB',
        disabled: '#F2F5F4',
      },
    },
    identity: {
      primary: '#008059',
      secondary: '#84ECAA',
      tertiary: '#B2EBD0',
      alternate: '#000000',
    },
    expressive: {
      primary: '#B2EBD0',
      secondary: '#CBF2E3',
      inverted: '#008059',
    },
    context: {
      positive: '#E8FCEF',
      secondary: '#10A355',
    },
  },
  foreground: {
    neutral: {
      primary: {
        default: '#0D0F0F',
        inverted: '#FFFFFF',
      },
      secondary: {
        default: '#4F5250',
        inverted: hexToRgba('#FFFFFF', 0.88),
      },
      tertiary: {
        default: '#767A78',
      },
      alternate: {
        inverted: '#84ECAA',
      },
    },
    action: {
      primary: {
        default: '#FFFFFF',
        inverted: '#0D0F0F',
        disabled: '#767A78',
      },
      secondary: {
        default: '#005C40',
        inverted: '#003D2B',
        disabled: '#A0A3A2',
      },
      tertiary: {
        default: '#008059',
        disabled: '#A0A3A2',
      },
      alternate2: {
        default: '#0D0F0F',
        disabled: '#767A78',
      },
      inverted: {
        primary: {
          default: '#003D2B',
          disabled: hexToRgba('#000000', 0.48),
        },
        secondary: {
          default: '#FFFFFF',
          disabled: hexToRgba('#FFFFFF', 0.56),
        },
      },
    },
    identity: {
      primary: '#008059',
      secondary: '#FF8E55',
      tertiary: '#51B88E',
    },
    info: {
      primary: '#1490A3',
    },
    critical: {
      primary: '#A31414',
    },
    positive: {
      primary: '#007A25',
    },
    warning: {
      primary: '#B84D16',
    },
    context: {
      critical: '#B83737',
      positive: '#008F4F',
      warning: '#D47619',
    },
    expressive: {
      secondary: '#51B88E',
      inverted: '#79E8A2',
      primary: '#008059',
      tertiary: '#FFA954',
    },
  },
  border: {
    neutral: {
      primary: {
        default: '#DCE0DE',
        inverted: '#FFFFFF',
      },
      secondary: {
        default: '#A0A3A2',
      },
      tertiary: {
        default: '#0D0F0F',
      },
      _UNSAFE_NAME_: '#EDEDED',
    },
    expressive: {
      primary: '#008059',
    },
    action: {
      inverted: {
        secondary: {
          default: '#FFFFFF',
          disabled: hexToRgba('#FFFFFF', 0.56),
        },
      },
      secondary: {
        default: '#008059',
        disabled: '#DCE0DE',
      },
      alternate: {
        selected: '#008059',
        focused: '#FF8E55',
      },
      critical: {
        default: '#A31414',
      },
    },
  },
} as const

// SPACING

export const space = {
  none: measure[0],
  smallest: measure[4],
  smaller: measure[8],
  small: measure[12],
  main: measure[16],
  large: measure[24],
  larger: measure[32],
  largest: measure[48],
  massive: measure[64],
  colossal: measure[96],
} as const

export const insets = {
  square: {
    smaller: `${space.smaller}`,
    small: space.small,
    main: `${space.main}`,
    large: space.large,
    larger: space.larger,
  },
  squished: {
    smaller: `${space.smaller} ${space.small}`,
    small: `${space.small} ${space.main}`,
    main: `${space.main} ${space.large}`,
    large: `${space.large} ${space.larger}`,
    larger: `${space.larger} ${space.largest}`,
  },
}

// BORDER

export const borderWidth = {
  none: '0',
  main: '1px',
  focus: '2px',
}

export const radius = {
  none: `0rem`,
  smallest: `0.25rem`,
  smaller: `0.5rem`,
  small: `0.75rem`,
  main: `1rem`,
  large: `1.5rem`,
  infinite: `9999px`,
}

export const focusStyle = (style?: string) => `
  &:focus-visible {
    outline: 2px solid orange;
    outline-offset: 2px;
    ${style ?? ''}
  }
`
